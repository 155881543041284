import { StrictModal } from '@c/Ui'
import Cookies from 'js-cookie'
import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { postPayment } from '@/api/payment.api'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { setPayed } from '@/core/store/catalog.store'
import { closeModal } from '@/core/store/ui.store'

export const PaymentModal = () => {
  const dispatch = useAppDispatch()

  const { t, i18n } = useTranslation('payment')

  const prices = [2.99, 4.99, 9.99]
  let priceValue = Cookies.get('price')
  let price = 9.99
  if (priceValue === undefined) {
    price = prices[Math.floor(Math.random() * prices.length)]
    Cookies.set('price', price.toString())
    priceValue = Cookies.get('price')
    //If we fail to set cookie for whatever reason, the price is 9.99
    if (priceValue === undefined) {
      priceValue = '9.99'
      price = 9.99
    }
  }

  const handleAcceptClick = useCallback(async () => {
    const isPaymentDone = await postPayment()

    if (isPaymentDone) {
      // toast.success(t('toastSuccess'))
      ReactGA.event({
        category: 'payment',
        action: 'PaymentAgreed',
        value: price,
      })
      dispatch(setPayed(true))
      dispatch(closeModal())
    }
  }, [i18n.language])

  const handleNotNowClick = useCallback(async () => {
    dispatch(closeModal())
  }, [i18n.language])

  const handleRejectClick = () => {
    //TODO: Payment will come here
    // toast.success(t('toastSuccess'))
    dispatch(setPayed(true))
    dispatch(closeModal())
    ReactGA.event({
      category: 'payment',
      action: 'PaymentRejected',
      value: price,
    })
  }

  return (
    <StrictModal name="payment">
      <div className="special">
        <div className="special-continue">
          <p>to continue:</p>
        </div>
        <div className="special-div">
          <div className="special-bar">
            <div className="special-offer">
              <p className="special-offer-big">Special offer</p>
            </div>
            <div className="special-text">
              <p className="special-text-small">
                All 12 tours and 450 stories for walk around mode for just &euro;7!
              </p>
            </div>
          </div>
          <div className="special-button-overlay" onClick={handleAcceptClick}>
            <div className="special-button-div">
              <a href="#">
                <div id="special-button" className="special-button">
                  <span>
                    BUY
                    <br />
                    NOW
                  </span>
                </div>
                <div className="special-button-circle"></div>
              </a>
            </div>
          </div>
          <div className="not-now-button-overlay" onClick={handleAcceptClick}>
            <div className="not-now-button-div button-notnow">
              <a href="#">
                <div id="not-now-button" className="not-now-button">
                  <span>
                    NOT
                    <br />
                    NOW
                  </span>
                </div>
                <div className="not-now-button-circle"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="special">
        <div className="special-continue">
          <p>to continue:</p>
        </div>
        <div className="special-div">
          <div className="special-bar">
            <div className="special-offer">
              <p className="special-offer-big">Special offer</p>
            </div>
            <div className="special-text">
              <p className="special-text-small">
                All 12 tours and 450 stories for walk around mode for just 8&euro; !
              </p>
            </div>
          </div>
          <div className="special-button-overlay">
            <div className="special-button-div">
              <a href="#" onClick={handleAcceptClick}>
                <div className="special-button-circle"></div>
                <div className="special-button">
                  <span>
                    BUY
                    <br />
                    NOW
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="payment-modal">
        <h4>{t('title')}</h4>

        <p>{t('subtitle', { price })}</p>

        <div className="payment-modal__actions">
          <a href="#" className="btn" onClick={handleAcceptClick}>
            {t('actions.continue')}
          </a>
          <a href="#" className="btn _accent" onClick={handleRejectClick}>
            {t('actions.refuse')}
          </a>
        </div>
      </div> */}
    </StrictModal>
  )
}
