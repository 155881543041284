import { AudioPlayer } from '@c/Ui'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  VKShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceShareButton,
  XIcon,
} from 'react-share'
import { toast } from 'react-toastify'

import { IMapObject } from '@/core/interface'
import { useAppDispatch } from '@/core/store'

interface IMapProps {
  data: IMapObject | null
  onClose: () => void
  active: boolean
  excursionStart: boolean
  currentStep: any
  onCloseRoute: any
  setCurrentStep: any
  pointsLength: number | undefined
}

const InfoWindow: React.FC<IMapProps> = ({
  data,
  onClose,
  active,
  excursionStart,
  currentStep,
  onCloseRoute,
  setCurrentStep,
  pointsLength,
}) => {
  const { t } = useTranslation('info')

  const [mode, setMode] = useState<'low' | 'middle' | 'high'>('middle')
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const descrRef = useRef<HTMLParagraphElement>(null)

  const shareUrl = data?.xid ? `${window.location.origin}/map?interestPoint=${data.xid}` : null

  useEffect(() => {
    const checkOverflow = () => {
      if (descrRef.current) {
        const element = descrRef.current

        // Reset any previously applied styles
        element.style.display = ''
        element.style.webkitLineClamp = ''
        element.style.webkitBoxOrient = ''
        element.style.overflow = 'visible'
        element.style.maxHeight = ''

        // Force a reflow to ensure the styles are applied
        void element.offsetHeight

        const lineHeight = parseFloat(getComputedStyle(element).lineHeight)
        const actualHeight = element.scrollHeight
        const lines = actualHeight / lineHeight
        const newIsOverflowing = lines > 2.2

        setIsOverflowing(newIsOverflowing)

        // Apply truncation styles if overflowing and not expanded
        if (newIsOverflowing && !isExpanded) {
          element.style.display = '-webkit-box'
          element.style.webkitLineClamp = '2'
          element.style.webkitBoxOrient = 'vertical'
          element.style.overflow = 'hidden'
          element.style.maxHeight = `${lineHeight * 2}px`
        } else if (isExpanded) {
          // Ensure full content is shown when expanded
          element.style.maxHeight = `${actualHeight}px`
          element.style.overflow = 'visible'
        }
      }
    }

    checkOverflow()

    window.addEventListener('resize', checkOverflow)

    return () => {
      window.removeEventListener('resize', checkOverflow)
    }
  }, [data, mode, isExpanded])

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev)
  }

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'high' ? 'middle' : 'high'))
  }

  const copyLinkButton = () => {
    toast.success('Link copied')
  }

  // console.log(data)

  return (
    <>
      <div className={`select_object ${active ? 'active' : ''} ${mode}`}>
        <button className="change__view" onClick={() => setMode(mode === 'low' ? 'middle' : 'low')}>
          <svg
            style={{
              transition: '.15s',
              transform: `rotate(${mode === 'low' ? '180deg' : '0deg'})`,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 14.5858L19.2929 7.29286L20.7071 8.70708L12 17.4142L3.29288 8.70708L4.70709 7.29287L12 14.5858Z"
              fill="#2B3537"
            />
          </svg>
        </button>
        {!excursionStart && (
          <button
            className="object_close"
            onClick={() => {
              onClose()
              setMode('middle')
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="24"
              viewBox="0 0 34 24"
              fill="none"
            >
              <path
                d="M26.8331 5L7.38867 19"
                stroke="#2B3537"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.38867 5L26.8331 19"
                stroke="#2B3537"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <p className="object__name">
          <span>{`${excursionStart ? `${currentStep + 1}.` : ''}`}</span> {data?.name}
        </p>
        {mode !== 'low' && (
          <div className="description-wrap">
            <p className={`object__description ${isExpanded ? 'opened' : 'closed'}`} ref={descrRef}>
              {data?.info?.descr}
            </p>
            {shareUrl && (
              <div className="share-buttons">
                <span>Share:</span>
                <FacebookShareButton
                  url={shareUrl}
                  // hashtag={'#hashtag'}
                  className="share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={'Hey! This is interesting: '}
                  className="share-button"
                >
                  <XIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  title={'Hey! This is interesting: '}
                  className="share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <span>
                  <button
                    className="share-button-copy-link"
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl)
                      toast.success('Link copied')
                    }}
                  >
                    <FontAwesomeIcon icon={faLink} />
                  </button>
                </span>
              </div>
            )}

            {isOverflowing && (
              <button onClick={toggleExpand}>
                Show {isExpanded ? 'less' : 'more'}
                <svg
                  style={{ transform: `rotate(${isExpanded ? '180deg' : '0'})` }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path d="M8 5L4.76837e-07 5L4 -1.27146e-07L8 5Z" fill="#006DEA" />
                </svg>
              </button>
            )}
          </div>
        )}
        {mode !== 'low' && <img className="object__photo" src={data?.preview?.source} alt="" />}
        {data && (
          <>
            <div className="audio-header">Listen to the story:</div>
            <AudioPlayer
              type={1}
              id={data.xid}
              files={data.audioFiles}
              autoPlaying={false}
              excursion={'y'}
            />
          </>
        )}
        {excursionStart && (
          <div className={`route__control`}>
            <button
              onClick={() => {
                onCloseRoute()
              }}
              className={`to_routes`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.34514 8.83327L8.58922 14.0774L7.41071 15.2559L0.154785 7.99994L7.41071 0.744019L8.58922 1.92253L3.34514 7.16661H15.5V8.83327H3.34514Z"
                  fill="#316096"
                />
              </svg>
              {t('routes')}
            </button>
            <div className="control__flex">
              {currentStep > 0 && (
                <button className="prev" onClick={() => setCurrentStep(currentStep - 1)}>
                  TO #{currentStep}
                </button>
              )}
              <button
                className="next"
                onClick={() => {
                  if (pointsLength && pointsLength === currentStep + 1) {
                    onCloseRoute()
                  } else {
                    setCurrentStep(currentStep + 1)
                  }
                }}
              >
                {pointsLength && pointsLength === currentStep + 1 ? 'finish' : 'next'}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default InfoWindow
