import 'react-sheet-slide/style.css'

import { MapDirectionsRenderer } from '@c/Catalog'
import { AudioDiv } from '@c/Catalog/AudioDiv'
import RoutesWindow from '@c/Catalog/Map/RoutesWindow'
import { GoogleMap, Marker, MarkerClusterer, TransitLayer } from '@react-google-maps/api'
import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import { FaLocationArrow, FaLocationCrosshairs } from 'react-icons/fa6'
import { useLocation, useSearchParams } from 'react-router-dom'

import SentryErrorBoundary from '@/components/SentryErrorBoundary'
import FirstTimePopup from '@/components/Ui/FirstTimePopup'
import {
  AudioFile,
  Coordinates,
  IMapObject,
  InterestPoint,
  Language,
  MapPoint,
} from '@/core/interface'
import { Excursion, TExcursionPoint } from '@/core/interface/Excursion'
import { useAppDispatch, useAppSelector } from '@/core/store'
import { getExcursionCategories, getExcursionPoints, getPoints } from '@/core/store/catalog.store'
import {
  setLocale,
  setLocationManual,
  setMapAutoCentering,
  setMapLoaded,
  setUserLocation,
} from '@/core/store/ui.store'

import InfoWindow from './InfoWindow'
import {
  circleOptions,
  containerStyle,
  createCenterControl,
  createNumberedIcon,
  icon_sizes,
  icon_sizes_x,
  icon_types,
  mapOptions,
} from './mapStyler'

interface IMainMapProps {
  markers: InterestPoint[]
  isExcursionBeingSelected: boolean
  setExcursionBeingSelected: (state: boolean) => void
  //The following params are to support opening routes window on predefined element
  initialExcursionId?: string
  initialCategoryId?: number
}

// Updated ClusteredMarkers component with display name
const ClusteredMarkers = React.memo(({ markers }: { markers: any[] }) => {
  return (
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
      minimumClusterSize={5}
      options={{
        clusterClass: 'clusterIcon',
        styles: [{ width: 40, height: 40, url: '' }],
      }}
    >
      {(clusterer) => (
        <>
          {markers.map((marker) => (
            <Marker
              key={marker.key}
              position={marker.position}
              icon={marker.icon}
              clusterer={clusterer}
              onClick={marker.onClick}
              zIndex={marker.zIndex}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  )
})

// Set the display name explicitly
ClusteredMarkers.displayName = 'ClusteredMarkers'

const MainMap: React.FC<IMainMapProps> = ({
  markers,
  isExcursionBeingSelected,
  setExcursionBeingSelected,
  initialExcursionId,
  initialCategoryId,
}) => {
  const { userLocation, userPosition, locale, locationManual, audioPlaying, mapAutoCentering } =
    useAppSelector((store) => store.uiState)

  const dispatch = useAppDispatch()
  const { i18n } = useTranslation()
  const location = useLocation()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [markDto, setPointerDataset] = useState<IMapObject | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()

  const { excursions, interestPoints, selectedInterestPoint } = useAppSelector(
    (store) => store.catalogStore,
  )
  const [places, setPlaces] = useState<any[]>([])
  const [selectedExcursion, setSelectedExcursion] = useState<Excursion | null>(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedPoint, setSelectedPoint] = useState('')
  // Function to set the current step and log the event with Google Analytics
  const setCurrentStepNTrack = (step: number) => {
    const idString = selectedExcursion && selectedExcursion.id ? `${selectedExcursion.id}` : ''
    if (step > currentStep) {
      ReactGA.event({
        category: 'excursion',
        action: 'ExcursionStep',
        label: `${idString}_${step}`,
      })
    } else {
      ReactGA.event({
        category: 'excursion',
        action: 'ExcursionStepBack',
        label: `${idString}_${step}`,
      })
    }
    setCurrentStep(step) // Set the new step
  }
  const screenWidth = window.screen.width
  // position setter around the positioning
  const [currentZoom, setCurrentZoom] = useState<number>(() => {
    if (location.state?.lat && location.state?.lon) {
      return screenWidth < 800 ? 17 : screenWidth < 1200 ? 18 : 19
    }
    return 15 // Default zoom
  })
  const [updateTrigger, setUpdateTrigger] = useState(0)

  const [customLatLng, setCustomLatLng] = useState<{ latitude: number; longitude: number } | null>(
    () => {
      if (location.state?.lat && location.state?.lon) {
        return { latitude: location.state.lat, longitude: location.state.lon }
      }
      return null // Default latlon
    },
  )

  useEffect(() => {
    if (customLatLng) {
      setLocationManual(true)
      // Clear the location state to prevent re-running this effect
      location.state = {}
    }
  }, [customLatLng])

  useEffect(() => {
    console.log(selectedInterestPoint)
    if (!selectedInterestPoint) return
    const coordinates = selectedInterestPoint.coordinates
    setCurrentZoom(17)
    // setLastManualCenter({ lat: coordinates.latitude, lng: coordinates.longitude })
    // setCustomLatLng(coordinates)
    setTimeout(() => setCustomLatLng(coordinates), 300)

    handleMarkerClick(null, selectedInterestPoint)
  }, [selectedInterestPoint])

  //On Samsung browser mainly, prevent zooming of the entire app page
  //when map is pinched
  useEffect(() => {
    const preventDefault = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    }

    document.addEventListener('touchmove', preventDefault, { passive: false })

    return () => {
      document.removeEventListener('touchmove', preventDefault)
    }
  }, [])

  useEffect(() => {
    if (searchParams.get('interestPoint') && !selectedInterestPoint) {
      // console.log(searchParams.get('interestPoint'))
      // selectedExcursion.points.filter((item) => item.title)
      // console.log(interestPoints)
      // console.log(interestPoints.filter((item) => item.id == searchParams.get('interestPoint')))
      if (interestPoints.filter((item) => item.id == searchParams.get('interestPoint')).length) {
        const coordinates = interestPoints.filter(
          (item) => item.id == searchParams.get('interestPoint'),
        )[0].coordinates
        setCurrentZoom(17)
        // setLastManualCenter({ lat: coordinates.latitude, lng: coordinates.longitude })
        // setCustomLatLng(coordinates)
        setTimeout(() => setCustomLatLng(coordinates), 300)

        handleMarkerClick(
          null,
          interestPoints.filter((item) => item.id == searchParams.get('interestPoint'))[0],
        )
      }
    }
  }, [])

  const createMarkerIcon = (
    id: string | null,
    window: any,
    idx: number | null,
    categories: string[],
    zoom?: number,
    heading?: number | null,
    isHighlighted = false,
  ) => {
    if (!zoom) {
      zoom = 13
    }

    const size_cat = categories && categories.length > 0 ? categories[0] : '10'
    const in_type = categories && categories.length > 1 ? categories[1] : 'O'
    let zindex = 0
    switch (size_cat) {
      case '10':
        zindex = 1
        break
      case '7':
        zindex = 2
        break
      case '5':
        zindex = 3
        break
      case '3':
        zindex = 4
        break
      default:
        zindex = 5
    }

    if (idx !== null) {
      const icon_file = createNumberedIcon(idx + 1, isHighlighted)

      const icon = {
        url: icon_file,
        scaledSize: new window.google.maps.Size(32, 32),
        anchor: new window.google.maps.Point(16, 16),
        zIndex: 1000,
      }
      return icon
    }

    if (size_cat == 'user-current-location') {
      return heading
        ? {
            path: FaLocationArrow({}).props.children[0].props.d,
            anchor: new window.google.maps.Point(256, 256),
            strokeColor: '#c04b35',
            fillColor: '#CB4335',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.065,
            rotation: -45 + (heading || 0),
            zindex: 0,
          }
        : {
            path: FaLocationCrosshairs({}).props.children[0].props.d,
            anchor: new window.google.maps.Point(256, 256),
            strokeColor: '#c04b35',
            fillColor: '#CB4335',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 0.065,
            zindex: 0,
          }
    }

    let icon_file = icon_types.get(in_type) ?? '/img/new/star.png'
    if (selectedPoint == id) {
      // console.log(selectedPoint)
      // console.log(icon_file)
      // console.log(zindex)
      icon_file = '/img/new/base-selected.png'
    }
    const icn_sizes = (zoom < 20 ? icon_sizes_x.get(zoom) : icon_sizes_x.get(19)) ?? icon_sizes

    let size_pix = icn_sizes.get(size_cat) ?? 12
    if (size_pix <= 18) {
      icon_file = '/img/new/smal.png'
      size_pix = 16
    }

    const icon = {
      url: icon_file,
      scaledSize: new window.google.maps.Size(size_pix, size_pix), // Adjust the size as needed
      zindex: isHighlighted ? 1000 : zindex,
    }

    // console.log(icon)

    return icon
  }

  const createAudioDiv = () => {
    if (audioPlaying != null) {
      return <AudioDiv audioPlaying={audioPlaying} />
    } else {
      return null
    }
  }

  // Provide default values for mapCenter and userLocation
  const defaultCenter = { lat: 41.89021, lng: 12.4923 } // Center of Rome
  const defaultUserLocation = { latitude: 41.89021, longitude: 12.4923 }
  const [lastManualCenter, setLastManualCenter] = useState<google.maps.LatLngLiteral | null>(null)

  // "smart" center - userLocation is changed rarely
  const mapCenter = useMemo(() => {
    if (customLatLng) {
      return {
        lat: customLatLng.latitude,
        lng: customLatLng.longitude,
      }
    }

    if (mapAutoCentering && userLocation) {
      return {
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      }
    }

    if (lastManualCenter) {
      return lastManualCenter
    }

    return defaultCenter
  }, [userLocation, customLatLng, mapAutoCentering])

  const effectiveUserLocation = userLocation || defaultUserLocation

  // map events
  const onLoad = useCallback(function callback(map: any) {
    dispatch(setMapLoaded(false))
    setMap(map)

    const bounds = new window.google.maps.LatLngBounds()
    markers.forEach((marker) => {
      bounds.extend(
        new google.maps.LatLng(marker.coordinates.latitude, marker.coordinates.longitude),
      )
    })

    // Map reset button
    const centerControlDiv = document.createElement('div')
    centerControlDiv.classList.add('centerControl')
    centerControlDiv.classList.add('hide')
    const centerControl = createCenterControl({ onClickHandler: resetLocation })
    centerControlDiv.appendChild(centerControl)

    map.controls[google.maps.ControlPosition.LEFT_TOP].push(centerControlDiv)

    setMap(map)
    dispatch(setMapLoaded(true))
  }, [])

  const onUnmount = useCallback(function callback(_map: any) {
    setMap(null)
  }, [])

  useEffect(() => {
    if (mapAutoCentering) {
      const centerBtn = document.querySelector('.centerControl')
      // @ts-ignore
      centerBtn?.classList.add('hide')
    } else {
      const centerBtn = document.querySelector('.centerControl')
      // @ts-ignore
      centerBtn?.classList.remove('hide')
    }
  }, [mapAutoCentering])

  const handleMarkerClick = useCallback(
    async (e: any, marker: MapPoint, index?: number) => {
      // console.log('Marker Click', e, marker)
      ReactGA.event({
        category: 'click',
        action: 'MarkerClick',
        label: marker.title,
      })
      // Type guard function
      function isInterestPoint(point: MapPoint): point is InterestPoint {
        return 'audioFiles' in point
      }

      const audioFiles: AudioFile[] = isInterestPoint(marker) ? marker.audioFiles : []

      setPointerDataset({
        xid: marker.id,
        name: marker.title,
        point: {
          lat: marker.coordinates.latitude,
          lon: marker.coordinates.longitude,
        },
        info: {
          descr: marker.description,
        },
        preview: {
          source: marker.image,
        },
        audioFiles: audioFiles,
      })
      // console.log(marker)
      setSelectedPoint(marker.id)
      // Update currentStep if in excursion mode and index is provided
      if (selectedExcursion && typeof index === 'number') {
        setCurrentStepNTrack(index)
      } else {
        // Trigger a re-render
        setUpdateTrigger((prev) => prev + 1)
      }
    },
    [selectedExcursion, currentStep],
  )

  const onZoomChanged = useCallback(() => {
    const zoom = map?.getZoom()
    if (zoom !== undefined) {
      setCurrentZoom(zoom ?? 13)
    }
    //??? dispatch(setMapAutoCentering(false))
  }, [map])

  const onDragEnd = useCallback(() => {
    dispatch(setMapAutoCentering(false))

    if (map) {
      const newCenter = map.getCenter()
      if (newCenter) {
        setLastManualCenter({ lat: newCenter.lat(), lng: newCenter.lng() })
      }
    }
  }, [map])

  const fetchPosition = useCallback(
    debounce(async (newLocation: Coordinates) => {
      dispatch(getPoints({ ...newLocation, locale: i18n.language as Language }))
    }, 300),
    [i18n.language],
  )

  const resetLocation = useCallback(() => {
    setCustomLatLng(null)
    setLastManualCenter(null)
    dispatch(setMapAutoCentering(true))
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let { latitude, longitude } = position.coords
          //TODO: Make it a nicer solution
          if (
            (latitude > 42.05 || latitude < 41.72) &&
            (longitude < 12.146 || longitude > 12.7777)
          ) {
            latitude = 41.89021
            longitude = 12.4923
          }
          dispatch(setUserLocation({ latitude, longitude }))
          dispatch(getPoints({ latitude, longitude, locale: i18n.language as Language }))
          dispatch(setLocationManual(false))
          map?.setCenter({ lat: latitude, lng: longitude })
        },
        (error) => {
          console.error('Error obtaining location:', error)
          //TODO: another hack
          dispatch(setUserLocation({ latitude: 41.89021, longitude: 12.4923 }))
        },
        {
          enableHighAccuracy: false,
          timeout: 1000,
          maximumAge: Infinity,
        },
      )
    } catch {
      console.error('Geo error')
    }
  }, [locale])

  const renderCount = useRef<number>(0)

  useEffect(() => {
    dispatch(setLocale(i18n.language as Language))

    if (renderCount.current >= 1) {
      resetLocation()
    }

    renderCount.current = renderCount.current + 1
  }, [i18n.language])

  useEffect(() => {
    if (customLatLng) {
      fetchPosition({
        latitude: customLatLng.latitude,
        longitude: customLatLng.longitude,
      })
    }
  }, [customLatLng])

  useEffect(() => {
    if (locationManual && map && userLocation) {
      map.setCenter({ lat: userLocation.latitude, lng: userLocation.latitude })
    }
  }, [locationManual])

  const ready = mapCenter && userLocation

  const getFilteredExcursionPoints = (points: TExcursionPoint[]) =>
    points.filter((item) => item.title)

  const memoizedMarkerList = useMemo(() => {
    //console.log(selectedExcursion?.points)
    //if (!window.google) return [] //TODO: this could result in racing condition (map not loaded yet, but markers are - fix this)
    const r = (
      selectedExcursion ? [...getFilteredExcursionPoints(selectedExcursion.points)] : [...markers]
    ).map((marker: MapPoint, idx) => {
      const isCurrentStep = selectedExcursion && idx === currentStep
      const isSelected = markDto?.xid === marker.id
      // console.log(marker)
      return {
        key: idx,
        icon: createMarkerIcon(
          marker?.id,
          window,
          selectedExcursion ? idx : null,
          marker.category,
          map?.getZoom(),
          null,
          isCurrentStep || selectedExcursion?.points.length === 1 || isSelected,
        ),
        clickable: true,
        onClick: (e: google.maps.MapMouseEvent) =>
          handleMarkerClick(e, marker, selectedExcursion ? idx : undefined),
        position: {
          lat: marker.coordinates.latitude,
          lng: marker.coordinates.longitude,
        },
        isOpen: false,
        zIndex: isCurrentStep ? 2000 : isSelected ? 1000 : 0, // Prioritize current step, then selected marker
      }
    })
    return r
  }, [markers, currentZoom, selectedExcursion, currentStep, updateTrigger])

  useEffect(() => {
    if (isExcursionBeingSelected) {
      dispatch(getExcursionPoints())
      dispatch(getExcursionCategories())
    }

    if (selectedExcursion && currentStep < selectedExcursion?.points.length) {
      // @ts-ignore
      handleMarkerClick(null, selectedExcursion.points.filter((item) => item.title)[currentStep])
    }
  }, [isExcursionBeingSelected])

  const startExcursion = (id: string, firstPointId: string) => {
    setExcursionBeingSelected(false)
    setPointerDataset(null)
    const current: Excursion | undefined = excursions.find((item) => item.id === id)

    if (current) {
      const firstPoint = current.points.find((item) => item.id === firstPointId)
      if (firstPoint) {
        setPlaces([
          firstPoint.coordinates,
          ...current.points
            .filter((item) => item.id !== firstPointId)
            .map((point) => point.coordinates),
        ])
        setSelectedExcursion({
          ...current,
          points: [firstPoint, ...current.points.filter((item) => item.id !== firstPointId)],
        })
        setCurrentStep(0)

        ReactGA.event({
          category: 'excursion',
          action: 'ExcursionStart',
          label: `${id}`,
        })
      } else {
        console.error('First point not found in the tours!')
      }
    } else {
      console.error('Excursion not found!')
    }
  }

  const closeExcursion = () => {
    setExcursionBeingSelected(true)
    setPlaces([])
    setCurrentStep(0)
    setSelectedExcursion(null)
  }

  useEffect(() => {
    if (selectedExcursion && currentStep < selectedExcursion?.points.length) {
      // @ts-ignore
      handleMarkerClick(null, selectedExcursion.points.filter((item) => item.title)[currentStep])
    }
  }, [currentStep, selectedExcursion])

  useEffect(() => {
    if (isExcursionBeingSelected && selectedExcursion) {
      // @ts-ignore
      handleMarkerClick(null, selectedExcursion.points.filter((item) => item.title)[currentStep])
      setExcursionBeingSelected(false)
    }
  }, [isExcursionBeingSelected])

  return (
    <div>
      {/*TODO check audio div  audioPlaying != null ? createAudioDiv() : null*/}
      {/* <FirstTimePopup /> */}
      <div className="map" style={{ position: 'relative' }}>
        <SentryErrorBoundary boundaryId="googlemap">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={currentZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onZoomChanged={onZoomChanged}
            onDragEnd={onDragEnd}
            options={mapOptions as any}
          >
            {selectedExcursion && places.length > 0 && (
              <MapDirectionsRenderer places={places} travelMode={'WALKING'} />
            )}
            <TransitLayer />
            {
              // eslint-disable-next-line no-constant-condition
              selectedExcursion ? (
                // Render markers without clustering when an excursion is selected
                memoizedMarkerList.map((marker) => (
                  <Marker
                    key={marker.key}
                    position={marker.position}
                    icon={marker.icon}
                    onClick={marker.onClick}
                    zIndex={marker.zIndex}
                  />
                ))
              ) : (
                // Use MarkerClusterer when no excursion is selected
                <div>
                  <ClusteredMarkers key={memoizedMarkerList.length} markers={memoizedMarkerList} />
                </div>
              )
            }
            <Marker
              key={80000000}
              options={circleOptions}
              icon={createMarkerIcon(
                null,
                window,
                null,
                ['user-current-location'],
                map?.getZoom(),
                userPosition.heading,
              )}
              position={{ lat: userPosition.latitude, lng: userPosition.longitude }}
            />
          </GoogleMap>
        </SentryErrorBoundary>
        <SentryErrorBoundary boundaryId="infowindow">
          <InfoWindow
            active={!!markDto && !!markDto && !isExcursionBeingSelected}
            data={markDto}
            onClose={() => {
              setPointerDataset(null)
              setUpdateTrigger((prev) => prev + 1)
            }}
            excursionStart={!!selectedExcursion}
            currentStep={currentStep}
            onCloseRoute={() => {
              closeExcursion()
              setPointerDataset(null)
              setExcursionBeingSelected(true)
            }}
            setCurrentStep={setCurrentStepNTrack}
            pointsLength={selectedExcursion?.points.filter((item) => item.title).length}
          />
        </SentryErrorBoundary>
        <SentryErrorBoundary boundaryId="routewindow">
          <RoutesWindow
            markers={[]} //{markers}
            isExcursionBeingSelected={isExcursionBeingSelected}
            setExcursionBeingSelected={setExcursionBeingSelected}
            data={selectedExcursion}
            active={isExcursionBeingSelected && !selectedExcursion}
            onClose={() => setExcursionBeingSelected(false)}
            startExcursion={startExcursion}
            closeExcursion={closeExcursion}
            initialExcursionId={initialExcursionId}
            initialCategoryId={initialCategoryId}
          />
        </SentryErrorBoundary>
      </div>
    </div>
  )
}
export default MainMap
